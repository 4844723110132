.header {
    background-color: $sky-blue;
    position: relative;
    max-height: 120vw;
    width: calc(100vw + 1px);
    z-index: 2;

    h1 {
        color: white;
        font-family: $logo-font;
        font-size: $font-size-6;
        font-weight: bold;
        line-height: 1.6;
        text-align: center;
        padding: $gap-6 $gap-5;
        position: relative;

        .social-links {
            position: absolute;
            width: 100%;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: $gap-3;

            img {
                width: 20px;
                margin: 0 $gap-2;
                opacity: 0.9;
            }

            img:hover {
                opacity: 1;
            }
        }
    }

    img.mountains {
        left: -1px;
        width: calc(100vw + 2px);
        position: absolute;
    }

    @media (min-width: 250px) {
        h1 {
            font-size: $font-size-7;
        }
    }

    @media (min-width: 300px) {
        h1 {
            font-size: $font-size-8;
            padding: $gap-7 $gap-6;
        }
    }

    @media (min-width: 400px) {
        h1 {
            font-size: $font-size-9;
            text-align: left;
            padding: $gap-8 $gap-7;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .social-links {
                display: flex;
                flex-direction: column;
                grid-template-columns: 30px;
                justify-content: center;
                width: fit-content;
                position: static;
    
                img {
                    display: block;
                    width: 20px;
                    margin: $gap-3 0;
                }
            }
        }
    }

    @media (min-width: 500px) {
        h1 {
            font-size: $font-size-10;

            .social-links {
                img {
                    margin: $gap-4 0;
                }
            }
        }
    }

    @media (min-width: 700px) {
        h1 {
            font-size: $font-size-11;
            padding: $gap-8 $gap-8;

            .social-links {
                img {
                    margin: $gap-5 0;
                    width: 40px;
                }
            }
        }
    }
}

.header:after {
    content: "";
    height: 2px;
    width: 100%;
    z-index: -1;
    position: absolute;
    background-color: $black;
    bottom: 0;
}