footer {
    background-color: $sam-green;
    text-align: center;
    padding: $gap-4 0;
    margin-top: -1px;
    color: white;
    position: relative;

    .name {
        font-weight: 500;
        font-size: $font-size-2;
        margin-bottom: $gap-1;
    }

    .number {
        font-weight: 300;
        font-size: $font-size-1;
    }

    @media (min-width: 700px) {
        padding: $gap-5 0;

        .name {
            font-size: $font-size-3;
            margin-bottom: $gap-1;
        }
    
        .number {
            font-size: $font-size-2;
        }
    }
}

footer:before {
    content: "";
    height: 1px;
    background-color: $white;
    width: 200px;
    position: absolute;
    left: calc(50vw - 100px);
    top: 0;

    @media (min-width: 700px) {
        width: 240px;
        left: calc(50vw - 120px);
    }
}