$font-size-1: 10px;
$font-size-2: 12px;
$font-size-3: 14px;
$font-size-4: 16px;
$font-size-5: 18px;
$font-size-6: 20px;
$font-size-7: 24px;
$font-size-8: 30px;
$font-size-9: 36px;
$font-size-10: 48px;
$font-size-11: 54px;
$font-size-12: 60px;
$font-size-13: 72px;

$gap-1: 4px;
$gap-2: 8px;
$gap-3: 12px;
$gap-4: 16px;
$gap-5: 24px;
$gap-6: 32px;
$gap-7: 48px;
$gap-8: 64px;
$gap-9: 96px;
$gap-10: 128px;
$gap-11: 192px;
$gap-12: 256px;
$gap-13: 384px;
$gap-14: 512px;
$gap-15: 640px;
$gap-16: 768px;

$sky-blue: #4bcffa;
$black: #02020e;
$sam-green: #1abc9c;
$white: #d6ebff;

$logo-font: 'Roboto Slab', serif;
$font: Poppins;
