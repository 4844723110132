.sam {
    background-color: $sam-green;
    position: relative;
    padding: $gap-6 $gap-4;
    color: white;

    h2 {
        font-size: $font-size-5;
        margin-bottom: $gap-3;
        line-height: 1.6;
        font-weight: 800;
    }

    h3 {
        font-size: $font-size-4;
        line-height: 1.6;
        font-weight: 600;
        margin-top: $gap-6;
    }

    p {
        font-weight: 300;
        font-size: $font-size-2;
        line-height: 1.7;
    }

    .projects {
        display: grid;
        gap: $gap-5;

        .project {
            border-left: 2px solid white;
            padding-left: $gap-3;
            color: inherit;
            text-decoration: none;
            transition-duration: 0.2s;

            .name {
                font-weight: 500;
                margin-bottom: $gap-1;
            }
    
            .description {
                font-weight: 300;
                font-size: $font-size-2;
            }
        }

        .project:hover {
            color: #d6ebff;
            border-left-color: $white;
        }
    }

    .links {
        display: grid;
        gap: $gap-3;

        .link {
            display: grid;
            grid-template-columns: 20px 1fr;
            align-items: center;
            gap: $gap-4;
            cursor: pointer;
            color: inherit;
            text-decoration: none;

            img {
                width: 100%;
                opacity: 0.8;
                transition-duration: 0.2s;
            }

            span {
                color: inherit;
                text-decoration: none;
            }
        }

        .link:hover {
            img {
                opacity: 1;
            }
        }
    }

    @media (min-width: 300px) {
        padding: $gap-7 $gap-5;

        h2 {
            font-size: $font-size-6;
        }

        h3 {
            font-size: $font-size-5;
        }
    
        p {
            line-height: 1.8;
        }

    }

    @media (min-width: 350px) {
        padding: $gap-8 $gap-6;

        h2 {
            font-size: $font-size-7;
        }

        h3 {
            font-size: $font-size-6;
        }
    
        p {
            font-size: $font-size-3;
            margin-bottom: $gap-7;
        }

        .projects {
            gap: $gap-6;
            margin-bottom: $gap-7;
    
            .project {
                border-left-width: 3px;
                padding-left: $gap-3;
    
                .name {
                    margin-bottom: $gap-1;
                    font-size: $font-size-4;
                }
        
                .description {
                    font-size: $font-size-3;
                }
            }
        }

        .links {
            gap: $gap-5;
    
            .link {
                grid-template-columns: 30px 1fr;
                gap: $gap-4;
    
                img {
                    width: 100%;
                }
    
                span {
                    font-size: $font-size-4;
                }
            }
        }
    }

    @media (min-width: 500px) {
        padding: $gap-9 $gap-6;

        h2 {
            font-size: $font-size-8;
        }

        h3 {
            font-size: $font-size-7;
        }
    
        p {
            font-size: $font-size-4;
        }

        & > * {
            width: calc(500px - #{2 * $gap-6});
            margin-left: auto;
            margin-right: auto;
        }
    }

    @media (min-width: 700px) {
        h2 {
            width: calc(600px - #{2 * $gap-6});
            font-size: $font-size-10;
        }

        p {
            font-size: $font-size-5;
            width: calc(600px - #{2 * $gap-6});
            margin-bottom: $gap-8;
        }

        .projects {
            .project {
                .description {
                    max-width: 400px;
                }
            }
        }

        .info {
            display: flex;
            justify-content: space-around;
            width: 100%;
            align-items: flex-start;

            .links, .projects {
                width: fit-content;
                max-width: 50%;
            }
        }
    }

    @media (min-width: 1200px) {
        padding: $gap-9 $gap-8;

        h2 {
            width: calc(900px - #{2 * $gap-6});
            font-size: $font-size-12;
        }

        h3 {
            font-size: $font-size-8;
        }

        p {
            font-size: $font-size-7;
            width: calc(900px - #{2 * $gap-6});
            margin-bottom: $gap-9;
        }

        .info {
            max-width: 1400px;
        }

        .projects {

            h3 {
                margin-bottom: -$gap-2;
            }
    
            .project {
                border-left-width: 5px;
                padding-left: $gap-4;
    
                .name {
                    font-size: $font-size-6;
                }
        
                .description {
                    font-size: $font-size-4;
                    line-height: 1.9;
                }
            }
        }

        .links {
            gap: $gap-6;
    
            .link {
                grid-template-columns: 45px 1fr;
                gap: $gap-4;
    
                span {
                    font-size: $font-size-6;
                }
            }
        }
    }
}