.benefits:before {
    content: "";
    background-color: $black;
    width: 100%;
    left: 0;
    height: 2px;
    position: absolute;
    top: -1px;
    z-index: 10;
}

.benefits {
    padding: $gap-7 $gap-4;
    background-color: $black;
    position: relative;
    color: $white;

    .block {
        margin-bottom: $gap-8;
        text-align: center;

        h2 {
            font-size: $font-size-5;
            margin-bottom: $gap-4;
            line-height: 1.6;
            font-weight: 800;
        }

        p {
            font-weight: 300;
            font-size: $font-size-2;
            line-height: 1.7;
        }

        #ngl {
            display: none;
        }
    }

    @media (min-width: 300px) {
        padding: $gap-8 $gap-5 $gap-7;

        .block {
            margin-bottom: $gap-5;

            h2 {
                font-size: $font-size-6;
            }
        
            p {
                margin-bottom: $gap-9;
                line-height: 1.8;
            }
        }

    }

    @media (min-width: 350px) {
        padding: $gap-9 $gap-6 $gap-8;

        .block {
            margin-bottom: $gap-10;

            h2 {
                font-size: $font-size-7;
                margin-bottom: $gap-6;
            }
        
            p {
                font-size: $font-size-3;
            }
        }
    }

    @media (min-width: 500px) {
        padding: $gap-10 $gap-6 $gap-9;

        .block {
            width: calc(500px - #{2 * $gap-6});
            margin-left: auto;
            margin-right: auto;

            h2 {
                font-size: $font-size-8;
            }
        
            p {
                font-size: $font-size-4;
            }
        }        
    }

    @media (min-width: 700px) {
        padding: $gap-10 $gap-7 $gap-9;

        .block {
            margin: 0;
            height: 100vh;
            width: 100%;
            max-height: 600px;
            max-width: 710px;

            h2 {
                font-size: $font-size-10;
            }

            p {
                font-size: $font-size-5;
                line-height: 2;
            }
        }

        .block:nth-child(1) {
            max-width: 920px;
            margin-left: auto;
            margin-right: auto;
        }

        .block:nth-child(2) {
            text-align: left;
        }

        .block:nth-child(3) {
            text-align: right;
            margin-left: auto;
        }
    }

    @media (min-width: 1200px) {
        padding: $gap-10 $gap-8 $gap-9;

        .block {
            max-width: 920px;

            h2 {
                font-size: $font-size-12;
                margin-bottom: $gap-7;
            }

            p {
                font-size: $font-size-6;
                line-height: 2.5;
            }
        }

        .block:nth-child(2) {
            max-width: none;
            position: relative;

            h2, p {
                max-width: 920px;
            }

            #ngl {
                display: block;
                position: absolute;
                top: -150px;
                right: 5%;
                width: 400px;
                height: 400px;
                border-radius: 50%;
                border: 1px solid transparentize($white, 0.85);
            }
        }
    }

    @media (min-width: 1800px) {
        padding: $gap-10 calc(50vw + #{$gap-8} - 920px) $gap-9;
    }
}